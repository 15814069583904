import appStrings from '@/app/utility/string.utility';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import DatePicker from 'vue2-datepicker';
export default {
  props: ['partyRowDetails'],
  components: {
    UploadDocument,
    DatePicker
  },
  data() {
    return {
      backgroundVerificationData: [],
      backgroundVerificationFields: [
        {
          key: 'creation_date'
        },
        {
          key: 'status'
          // class: 'col-fix text-center'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'document'
        }
      ],
      partyRequestId: null,
      showBackgroundVerificationModal: false,
      registeredAddress: {
        bgvc_dtl_id: 0,
        add1: null,
        add2: null,
        country: 'India',
        state: null,
        city: null,
        pin: null,
        country_id: 100
      },
      siteAddress: {
        bgvc_dtl_id: 0,
        add1: null,
        add2: null,
        country: 'India',
        state: null,
        city: null,
        pin: null,
        country_id: 100
      },
      natureOfBusiness: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      bgvc_hdr_id: 0,
      bgvc_dtl_id1: 0,
      bgvc_dtl_id0: 0,
      checkAddress: 'unchecked',
      vsetCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      parentId: null,
      addressType: null,
      openKmAuth: null,
      showHistory: false,
      historyType: null,
      currentPage: 1,
      bgvcHdrId: 0,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      userId: null,
      status: null,
      directorsData: [],
      directorsFields: [
        // {
        //   key: 'add_row',
        //   label: 'Add',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        // {
        //   key: 'remove_row',
        //   label: 'Remove',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        {
          key: 'bgvc_dir_name',
          label: 'Name',
          class: 'text-center'
        },

        {
          key: 'bgvc_dir_pan_no',
          label: 'PAN',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_address',
          label: 'Address',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_email',
          label: 'Email',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_phone',
          label: 'Mobile',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_father_name',
          label: "Father's Name",
          class: 'text-center'
        }
      ],
      dateOfIncorporation: null,
      noOfDirectors: null,
      dateOfIncorporationInPayload: null,
      totalDirectorRows: 0,
      showDirectors: false,
      bgvcHdrIdForDirectors: null,
      directorsDocumentViewFlag: false,
      formType: null,
      totalRowsDirectors: null,
    };
  },
  mounted() {
    this.getRemedyMenuID();
    this.userId = this.$store.state.auth.userId;
    if (this.partyRowDetails) {
      this.partyRequestId = this.partyRowDetails.req_id;
      this.getBackgroundVerificationData();
    }
  },
  methods: {
    getBackgroundVerificationData() {
      const payload = {
        _page: 0,
        _limit: 100,
        party_req_id: this.partyRequestId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getBackgroundVerificationData', payload)
        .then(response => {
          this.loader = false;
          // this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.backgroundVerificationData = response.data.data.page;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    initiateBackgroundVerification(type) {
      if (type === 'create') {
        let count = 0;
        for (let i of this.backgroundVerificationData) {
          if (
            i.status === 'START BGVC' ||
            i.status === 'BGVC INITIATE' ||
            i.status === 'DRAFT'
          ) {
            count = count + 1;
          }
        }
        if (count > 0) {
          alert(
            'One of the record is already in START BGVC or BGVC INITIATE or DRAFT state'
          );
        } else {
          this.registeredAddress = {
            bgvc_dtl_id: 0,
            add1: null,
            add2: null,
            country: 'India',
            state: null,
            city: null,
            pin: null,
            country_id: 100
          };
          this.siteAddress = {
            bgvc_dtl_id: 0,
            add1: null,
            add2: null,
            country: 'India',
            state: null,
            city: null,
            pin: null,
            country_id: 100
          };
          this.directorsData = [];
          this.natureOfBusiness = null;
          this.checkAddress = 'unchecked';
          this.showBackgroundVerificationModal = true;
          this.status = 'UNSAVED';
          this.noOfDirectors = null;
          this.editMode = true;
          this.totalDirectorRows = 0;
          this.bgvcHdrId = 0;
          this.bgvcHdrIdForDirectors = 0;
        }
      } else {
        if (type.bgvc_hdr_id) {
          this.bgvcHdrIdForDirectors = type.bgvc_hdr_id;
          this.bgvcHdrId = type.bgvc_hdr_id;
        }
        this.registeredAddress = {
          bgvc_dtl_id: 0,
          add1: null,
          add2: null,
          country: null,
          state: null,
          city: null,
          pin: null
        };
        this.siteAddress = {
          bgvc_dtl_id: 0,
          add1: null,
          add2: null,
          country: null,
          state: null,
          city: null,
          pin: null
        };
        this.directorsData = [];
        this.checkAddress = false;
        this.natureOfBusiness = null;
        // this.status = type.status;
        // this.bgvc_hdr_id = type.bgvc_hdr_id;
        const payload = {
          bgVcHdrId: this.bgvcHdrId
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getBackgroundVerificationDetailsData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              // this.isSuccess = true;
              // this.responseMsg = response.data.message;
              this.status = response.data.data.status;
              let result = response.data.data.bgvc_dtls;
              this.registeredAddress = {
                bgvc_dtl_id: result[0].bgvc_dtl_id,
                add1: result[0].address1,
                add2: result[0].address2,
                country: result[0].country,
                state: result[0].state,
                city: result[0].city,
                pin: result[0].pincode
              };
              this.siteAddress = {
                bgvc_dtl_id: result[1].bgvc_dtl_id,
                add1: result[1].address1,
                add2: result[1].address2,
                country: result[1].country,
                state: result[1].state,
                city: result[1].city,
                pin: result[1].pincode
              };
              this.noOfDirectors = response.data.data.no_of_directors;
              this.dateOfIncorporation =
                response.data.data.date_of_incorporation;
              if (
                this.registeredAddress.add1 === this.siteAddress.add1 &&
                this.registeredAddress.add2 === this.siteAddress.add2 &&
                this.registeredAddress.country === this.siteAddress.country &&
                this.registeredAddress.state === this.siteAddress.state &&
                this.registeredAddress.city === this.siteAddress.city &&
                this.registeredAddress.pin === this.siteAddress.pin
              ) {
                this.checkAddress = 'checked';
              }
              this.directorsData = response.data.data.bgvc_dir_dtls;
              this.totalDirectorRows = response.data.data.bgvc_dir_dtls.length;
              this.natureOfBusiness = result[1].nature_of_business;
              this.dateOfIncorporation =
                response.data.data.date_of_incorporation;
              if (type === 'initiate') {
                this.showBackgroundVerificationModal = false;
              } else {
                this.showBackgroundVerificationModal = true;
              }
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
            this.showAlert = false;
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    saveBackgroundVerificationData(type) {
      if (
        !this.partyRowDetails.party_name ||
        (!this.partyRowDetails.email && !this.partyRowDetails.mob) ||
        !this.partyRowDetails.pan ||
        !this.dateOfIncorporation ||
        !this.noOfDirectors ||
        !this.registeredAddress.add1 ||
        !this.registeredAddress.country ||
        !this.registeredAddress.state ||
        !this.registeredAddress.city ||
        !this.registeredAddress.pin ||
        !this.siteAddress.add1 ||
        !this.siteAddress.country ||
        !this.siteAddress.state ||
        !this.siteAddress.city ||
        !this.siteAddress.pin
      ) {
        alert('Please enter mandatory fields');
      } else {
        if (
          this.registeredAddress.add1 === null ||
          this.registeredAddress.add1 === '' ||
          this.registeredAddress.country === null ||
          this.registeredAddress.country === '' ||
          this.registeredAddress.state === null ||
          this.registeredAddress.state === '' ||
          this.registeredAddress.city === null ||
          this.registeredAddress.city === '' ||
          this.registeredAddress.pin === null ||
          this.registeredAddress.pin === '' ||
          this.siteAddress.add1 === null ||
          this.siteAddress.add1 === '' ||
          this.siteAddress.country === null ||
          this.siteAddress.country === '' ||
          this.siteAddress.state === null ||
          this.siteAddress.state === '' ||
          this.siteAddress.city === null ||
          this.siteAddress.city === '' ||
          this.siteAddress.pin === null ||
          this.siteAddress.pin === ''
        ) {
          alert('Required Mandatory fields');
        } else {
          let directorDetails = [];
          for (let i of this.directorsData) {
            directorDetails.push({
              bgvc_dir_id: i.bgvc_dir_id,
              bgvc_dir_name: i.bgvc_dir_name,
              bgvc_dir_father_name: i.bgvc_dir_father_name,
              bgvc_dir_pan_no: i.bgvc_dir_pan_no,
              bgvc_dir_phone: i.bgvc_dir_phone,
              bgvc_dir_email: i.bgvc_dir_email,
              bgvc_dir_address: i.bgvc_dir_address
            });
          }
          let bgvcDetails = [
            {
              bgvc_dtl_id: this.registeredAddress.bgvc_dtl_id,
              address_type: 'REG',
              country: this.registeredAddress.country,
              state: this.registeredAddress.state,
              city: this.registeredAddress.city,
              pincode: this.registeredAddress.pin,
              address1: this.registeredAddress.add1,
              address2: this.registeredAddress.add2,
              nature_of_business: this.natureOfBusiness
            },
            {
              bgvc_dtl_id: this.siteAddress.bgvc_dtl_id,
              address_type: 'SITE',
              country: this.siteAddress.country,
              state: this.siteAddress.state,
              city: this.siteAddress.city,
              pincode: this.siteAddress.pin,
              address1: this.siteAddress.add1,
              address2: this.siteAddress.add2,
              nature_of_business: this.natureOfBusiness
            }
          ];
          const payload = {
            bgvc_hdr_id: this.bgvcHdrId,
            name: this.partyRowDetails.party_name,
            email: this.partyRowDetails.email,
            mobile_no: this.partyRowDetails.mob,
            pan_no: this.partyRowDetails.pan,
            party_req_id: this.partyRequestId,
            status: type === 'initiate' ? 'BGVC INITIATE' : 'DRAFT',
            no_of_directors: +this.noOfDirectors,
            date_of_incorporation: this.dateOfIncorporation,
            bgvc_dtls: bgvcDetails,
            bgvc_dir_dtls: directorDetails
          };
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/postBackgroundVerificationData', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.bgvcHdrId = response.data.data.bgvc_hdr_id;
                this.status = response.data.data.status;
                this.bgvcHdrIdForDirectors = response.data.data.bgvc_hdr_id;
                if (type === 'initiate') {
                  this.initiateBackgroundVerification('initiate');
                } else {
                  this.initiateBackgroundVerification('get');
                }
                this.getBackgroundVerificationData();
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.makeToast(this.responseMsg, 'success');
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
                this.makeToast(this.responseMsg, 'warning');
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
              this.makeToast(this.responseMsg, 'danger');
            });
        }
      }
    },
    backgroundVerificationModal(flag) {
      this.showBackgroundVerificationModal = flag;
    },
    checkUncheckAddress() {
      if (this.checkAddress === 'checked') {
        (this.siteAddress.add1 = this.registeredAddress.add1),
          (this.siteAddress.add2 = this.registeredAddress.add2),
          (this.siteAddress.country = this.registeredAddress.country),
          (this.siteAddress.state = this.registeredAddress.state),
          (this.siteAddress.city = this.registeredAddress.city),
          (this.siteAddress.pin = this.registeredAddress.pin);
      }
      //  else if (this.checkAddress === 'unchecked') {
      //   this.siteAddress = {
      //     bgvc_dtl_id: 0,
      //     add1: null,
      //     add2: null,
      //     country: null,
      //     state: null,
      //     city: null,
      //     pin: null
      //   };
      // }
    },
    openValueSetModal(vsetCode, addressType) {
      this.vsetCode = vsetCode;
      this.addressType = addressType;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.parent_value_set_id = 100;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_COUNTRY) {
        if (this.addressType === 'registered') {
          this.registeredAddress.country = item.country;
          this.parentId = item.country_id;
        } else if (this.addressType === 'site') {
          this.siteAddress.country = item.country;
          this.parentId = item.country_id;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        if (this.addressType === 'registered') {
          this.registeredAddress.state = item.state;
          this.checkSameAddress();
        } else if (this.addressType === 'site') {
          this.siteAddress.state = item.state;
          this.checkSameAddress();
        }
      }
    },
    getOpenKmAuthTokenUploadDocumentForDirectors(type) {
      if (type === 'view') {
        this.formType = 'bgvcDirectorsView';
      } else {
        this.formType = 'bgvcDirectors';
      }
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideDirectors(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideDirectors(flag, historyType) {
      this.showDirectors = flag;
      this.historyType = historyType;
      this.getUploadedDocDtlDirectors()
    },
    getOpenKmAuthTokenUploadDocument(hdrId) {
      this.bgvcHdrId = hdrId;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    getUploadedDocDtl(hdrId) {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: this.menuId,
        sub_menu_id: 0,
        record_id: hdrId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.closeBackgroundVerificationData(hdrId);
              const docList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.directorsData.push({
        bgvc_dir_id: 0,
        bgvc_dir_name: null,
        bgvc_dir_address: null,
        bgvc_dir_email: null,
        bgvc_dir_pan_no: null,
        bgvc_dir_father_name: null,
        bgvc_dir_phone: null
      });
    },
    removeRow(index) {
      if (this.directorsData[index].bgvc_dir_id === 0) {
        this.directorsData.splice(index, 1);
        this.noOfDirectors = this.directorsData.length;
      } else {
        const payload = {
          bgvc_dir_dtls: [
            {
              bgvc_dir_id: this.directorsData[index].bgvc_dir_id
            }
          ]
        };
        this.documentData = [];
        this.$store
          .dispatch('intWorkflow/deleteDirectorDetails', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (response.data.data.page.length > 0) {
                this.initiateBackgroundVerification('get');
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
      this.directorsData.splice(index, 1);
    },
    upperCaseFunction(panNumber, index) {
      this.directorsData[index].bgvc_dir_pan_no = panNumber.toUpperCase();
    },
    clearVsetValues(value) {
      if (value === 'regCountry') {
        this.registeredAddress.country = null;
      } else if (value === 'regState') {
        this.registeredAddress.state = null;
      } else if (value === 'siteCountry') {
        this.siteAddress.country = null;
      } else if (value === 'siteState') {
        this.siteAddress.state = null;
      }
    },
    // checkSameAddress(value) {
    //   if (
    //     value === 'add1' &&
    //     this.registeredAddress.add1 !== this.siteAddress.add1
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'add1' &&
    //     this.registeredAddress.add1 === this.siteAddress.add1
    //   ) {
    //     this.checkAddress = 'checked';
    //   } else if (
    //     value === 'add2' &&
    //     this.registeredAddress.add2 !== this.siteAddress.add2
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'add2' &&
    //     this.registeredAddress.add2 === this.siteAddress.add2
    //   ) {
    //     this.checkAddress = 'checked';
    //   } else if (
    //     value === 'city' &&
    //     this.registeredAddress.city !== this.siteAddress.city
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'city' &&
    //     this.registeredAddress.city === this.siteAddress.city
    //   ) {
    //     this.checkAddress = 'checked';
    //   } else if (
    //     value === 'pin' &&
    //     this.registeredAddress.pin !== this.siteAddress.pin
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'pin' &&
    //     this.registeredAddress.pin === this.siteAddress.pin
    //   ) {
    //     this.checkAddress = 'checked';
    //   }
    // }
    checkSameAddress() {
      if (
        this.registeredAddress.add1 === this.siteAddress.add1 &&
        this.registeredAddress.add2 === this.siteAddress.add2 &&
        this.registeredAddress.country === this.siteAddress.country &&
        this.registeredAddress.state === this.siteAddress.state &&
        this.registeredAddress.city === this.siteAddress.city &&
        this.registeredAddress.pin === this.siteAddress.pin
      ) {
        this.checkAddress = 'checked';
      } else {
        this.checkAddress = 'unchecked';
      }
    },
    createDirectorRows(number) {
      if (number == 0) {
        alert('At least one director must be entered');
        this.noOfDirectors = null;
      } else if (number < this.totalDirectorRows) {
        alert('Number of lines cannot be less than saved directors length');
      } else {
        if (this.directorsData.length !== 0) {
          if (this.totalDirectorRows === 0) {
            this.directorsData = [];
            for (let i = 0; i < number; i++) {
              this.directorsData.push({
                bgvc_dir_id: 0,
                bgvc_dir_name: null,
                bgvc_dir_address: null,
                bgvc_dir_email: null,
                bgvc_dir_pan_no: null,
                bgvc_dir_father_name: null,
                bgvc_dir_phone: null
              });
            }
          } else {
            let directorsArray = [];
            for (let i = 0; i < this.directorsData.length; i++) {
              if (this.directorsData[i].bgvc_dir_id !== 0) {
                directorsArray.push(this.directorsData[i]);
              }
            }
            this.directorsData = directorsArray;
            let loop = +number - this.directorsData.length;
            for (let i = 0; i < loop; i++) {
              this.directorsData.push({
                bgvc_dir_id: 0,
                bgvc_dir_name: null,
                bgvc_dir_address: null,
                bgvc_dir_email: null,
                bgvc_dir_pan_no: null,
                bgvc_dir_father_name: null,
                bgvc_dir_phone: null
              });
            }
          }
        } else {
          for (let i = 0; i < +number; i++) {
            this.directorsData.push({
              bgvc_dir_id: 0,
              bgvc_dir_name: null,
              bgvc_dir_address: null,
              bgvc_dir_email: null,
              bgvc_dir_pan_no: null,
              bgvc_dir_father_name: null,
              bgvc_dir_phone: null
            });
          }
        }
      }
    },
    disabledDatesFromSysDate(date) {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const monthNames = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
      ];
      this.currentDate = `${day}-${monthNames[month]}-${year}`;
      const restrictedDate = new Date(this.currentDate);
      restrictedDate.setHours(0, 0, 0, 0);
      return date > restrictedDate;
    },
    checkDuplicateDirector(type) {
      let countEmail = 0;
      let countPan = 0;
      let countPhone = 0;
      for (let i = 0; i < this.directorsData.length; i++) {
        for (let j = 0; j < this.directorsData.length; j++) {
          if (
            i !== j &&
            this.directorsData[i].bgvc_dir_email ===
              this.directorsData[j].bgvc_dir_email &&
            this.directorsData[j].bgvc_dir_email !== null
          ) {
            countEmail = countEmail + 1;
          } else if (
            i !== j &&
            this.directorsData[i].bgvc_dir_phone ===
              this.directorsData[j].bgvc_dir_phone &&
            this.directorsData[j].bgvc_dir_phone !== null
          ) {
            countPhone = countPhone + 1;
          } else if (
            i !== j &&
            this.directorsData[i].bgvc_dir_pan_no ===
              this.directorsData[j].bgvc_dir_pan_no &&
            this.directorsData[j].bgvc_dir_pan_no !== null
          ) {
            countPan = countPan + 1;
          }
        }
      }
      if (countPan > 0) {
        alert('Duplicate PAN Number');
      }
      if (countEmail > 0) {
        alert('Duplicate Email');
      }
      if (countPhone > 0) {
        alert('Duplicate Mobile Number');
      }
      if (countEmail === 0 && countPan === 0 && countPhone === 0) {
        // this.makeToast('Request Completed Successfully', 'success');
        if(type === 'saveClose') {
          this.saveBackgroundVerificationData('saveClose');
        } else if(type === 'initiate') {
          this.initiateBackgroundVerificationData('initiate');
        }
      }
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast'
      });
    },
    getUploadedDocDtlDirectors() {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: this.menuId,
        sub_menu_id: 1,
        record_id: this.bgvcHdrIdForDirectors
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.totalRowsDirectors = response.data.data.total_elements;
              const docList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            } else {
              // alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    initiateBackgroundVerificationData(type) {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: this.menuId,
        sub_menu_id: 1,
        record_id: this.bgvcHdrIdForDirectors
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            let count=0;
            for(let i of this.directorsData) {
              if(i.bgvc_dir_pan_no === null || i.bgvc_dir_pan_no === '') {
                count=count+1;
              }
            }
            if (
              response.data.data.page.length === 0 && count !== 0
            ) {
              alert("Please upload Director's Document or fill Director's Details manually");
            } else if(response.data.data.page.length !== 0 && count === 0) {
              alert("You have uploaded Director's Document also and filled Director's Details in the table")
            } else {
              this.saveBackgroundVerificationData(type);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkMandatoryConditions(data, type, index) {
      if (type === 'mobile') {
        if (data.length !== 10) {
          alert('Invalid Mobile Number');
          this.directorsData[index].bgvc_dir_phone = null;
        }
      } else if (type === 'email') {
        if (!data.includes('@')) {
          alert('Invalid Email');
          this.directorsData[index].bgvc_dir_email = null;
        }
      } else if (type === 'pan') {
        if (data.length !== 10) {
          alert('Invalid PAN');
          this.directorsData[index].bgvc_dir_pan_no = null;
        }
      } 
    },
    getRemedyMenuID() {
      const payload = {
        remedyName: "Background Verification"
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getRemedyMenuId', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.menuId=resp.data.data.menu_id;
            if(this.menuId){
                sessionStorage.setItem('bgvcMenuId', this.menuId)
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  }
};
