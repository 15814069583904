import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required, minLength } from 'vuelidate/lib/validators';
import backgroundVerification from '../partyDetails/backgroundVerification';

export default {
  name: 'addPartyOnBoarding',
  components: {
    backgroundVerification
  },
  props: ['partyDetails', 'userId'],
  validations: {
    name: { required },
    panNumber: { required, minLength: minLength(10) },
    templateType: {
      text: {
        required
      }
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      reqId: 0,
      name: null,
      email: null,
      contactNumber: null,
      panNumber: null,
      panNotAvailable: false,
      templateType: { value: null, text: null },
      internalContactName: null,
      internalContactEmail: null,
      internalContactNumber: null,
      approvalStatus: 'DRAFT',
      showValueSetModal: false,
      employeeId: this.$store.state.auth.userDtl.employee_id,
      supplierData: [
        {
          serial_no: null,
          check_point: null,
          scale: null,
          actual_score: null,
          remarks: null
        }
      ],
      supplierFields: [
        {
          key: 's_no',
          label: 'S. No',
          stickyColumn: true
        },
        {
          key: 'check_point'
        },
        {
          key: 'scale',
          stickyColumn: true
        },
        {
          key: 'actual_score'
        },
        {
          key: 'remarks'
        }
      ],
      totalScore: null,
      rating: null,
      totalAmount: null,
      supplierName: null,
      panVerifyDetails: {
        category: null,
        client_id: null,
        full_name: null,
        pan_number: null
      },
      backgroundVerificationData: [],
      backgroundVerificationFields: [
        {
          key: 'creation_date'
          // class: 'col-fix text-center'
        },
        {
          key: 'status'
          // class: 'col-fix text-center'
        },
        {
          key: 'initiate'
          // class: 'col-fix text-center'
        }
      ],
      showBackgroundVerificationModal: false,
      partyRequestId: null,
      registeredAddress: {
        add1: null,
        add2: null,
        country: null,
        state: null,
        city: null,
        pin: null
      },
      siteAddress: {
        add1: null,
        add2: null,
        country: null,
        state: null,
        city: null,
        pin: null
      },
      natureOfBusiness: null,
      partyData: {
        party_name: null,
        email: null,
        mob: null,
        pan: null,
        req_id: null
      },
      bgvcConsentFlag: false
    };
  },
  mounted() {
    if (this.partyDetails) {
      this.getPartyOnBoardingDetailsById();
    } else {
      this.editMode = true;
      this.getInternalContactDetails(this.employeeId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.saveNewFunction();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getInternalContactDetails(employeeId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getInternalContactDetails', employeeId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.internalContactName = result.employee_name;
            this.internalContactEmail = result.official_email_id;
            this.internalContactNumber = result.mobile;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyOnBoardingDetailsById() {
      const payload = {
        party_req_id: this.reqId,
        user_id: this.userId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyOnBoardingDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.reqId = result.req_id;
            this.name = result.party_name;
            this.email = result.email;
            this.contactNumber = result.mob;
            this.panNumber = result.pan;
            this.panNotAvailable = result.pan_not_avl;
            this.internalContactName = result.int_contact_name;
            this.internalContactEmail = result.int_contact_email;
            this.internalContactNumber = result.int_contact_mob;
            this.approvalStatus = result.approval_status;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    isMobileValid(payload) {
      if (payload.mob) {
        if (payload.mob.length == 10) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    saveNewFunction() {
      if (this.panNumber) {
        if (commonHelper.isValidPanNumber(this.panNumber)) {
          this.checkPanVerify();
        } else {
          this.panNumber = null;
        }
      } else {
        this.addEditPartyOnBoarding();
      }
    },
    checkPanVerify() {
      const payload = {
        id_number: this.panNumber
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkPanVerify', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (result == 'Invalid PAN') {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.data.data;
            } else if (result.message_code == 'success') {
              this.panVerifyDetails = result.data;
              if (this.panNumber.charAt(3) === 'P') {
                this.panAadhaarVerify();
              } else {
                this.isVendorExist();
              }
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.data.data;
            }
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    panAadhaarVerify() {
      const payload = {
        pan_number: this.panNumber,
        consent: "Y"
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/panAadhaarVerify', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (result.code === '1016') {
              this.isVendorExist();
            } else if(result.code === '1004') {
              alert('PAN does not exist');
            } else if(result.code === '1006') {
              alert('Aadhar is not linked');
            }
          } else if(response.status === 400) { 
            if(response.data.data.code === 'INVALID_INDIVIDUAL_PAN') {
              alert('Invalid Individual PAN Number');
            } else if(response.data.data.code === 'BAD_REQUEST') {
              alert('PAN Number is required');
            } 
          }
        })
        .catch(() => {
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    isVendorExist() {
      const payload = {
        REG_DETAILS: {
          REG_DETAILS_ITEM: [
            {
              REG_TYPE: 'PAN',
              REG_NUMBER: this.panNumber
            }
          ]
        }
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/isVendorExist', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.OutputParameters[0];
            if (result.RETURN_CODE === 'N') {
              this.addEditPartyOnBoarding();
            } else if (result.RETURN_CODE === 'S') {
              if (this.panNumber.charAt(3) === 'P') {
                const promt = confirm(
                  'PAN Number already exist with another vendor\n' +
                    'Do you want to continue ?'
                );
                if (promt) {
                  this.addEditPartyOnBoarding();
                }
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = `PAN Number already exist with Vendor Name: ${result.RETURN_MESSAGE}`;
              }
            }
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditPartyOnBoarding() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.contactNumber = this.contactNumber
          ? this.contactNumber.replace(/[^0-9]/g, '')
          : this.contactNumber;
          this.email = this.email
          ? this.email.replace(/\s+/g, '')
          : this.email;
        if (this.email || this.contactNumber) {
          const payload = {
            req_id: this.reqId,
            party_name: this.name,
            email: this.email ? this.email.trim() : this.email,
            mob: this.contactNumber ? this.contactNumber : null,
            pan: this.panNumber,
            template_type_code: this.templateType.value,
            pan_not_avl: this.panNotAvailable,
            int_contact_name: this.internalContactName,
            int_contact_email: this.internalContactEmail,
            int_contact_mob: this.internalContactNumber,
            bgvc_applicable_flag: this.bgvcConsentFlag ? true : false
          };
          if (this.isMobileValid(payload)) {
            this.loader = true;
            this.$store
              .dispatch('intWorkflow/addEditPartyOnBoarding', payload)
              .then(response => {
                this.loader = false;
                this.showAlert = true;
                if (response.status === 201) {
                  this.editMode = false;
                  this.isSuccess = true;
                  this.responseMsg = response.data.message;
                  this.reqId = response.data.data.req_id;
                  this.partyRequestId = response.data.data.req_id;
                  this.partyData = {
                    party_name: response.data.data.party_name,
                    email: response.data.data.email,
                    mob: response.data.data.mob,
                    pan: response.data.data.pan,
                    req_id: response.data.data.req_id
                  };
                  this.getBackgroundVerificationData();
                  this.getHistory(this.reqId);
                  this.$emit('updateList');
                  this.addPanVerifyDetails();
                } else {
                  this.isSuccess = false;
                  this.responseMsg = response.response.data.message;
                }
              })
              .catch(() => {
                this.loader = false;
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              });
          } else {
            alert('Please Enter Correct Mobile Number.');
          }
        } else {
          alert('Please Enter Email-Id or Mobile Number.');
        }
      }
    },
    addPanVerifyDetails() {
      const payload = {
        party_req_id: this.reqId,
        pan_ver_id: 0,
        category: this.panVerifyDetails.category,
        client_id: this.panVerifyDetails.client_id,
        full_name: this.panVerifyDetails.full_name,
        pan_number: this.panVerifyDetails.pan_number
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/addPanVerifyDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.getPanVerifyDetailsById();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getPanVerifyDetailsById() {
      const payload = {
        party_req_id: this.reqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPanVerifyDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.panVerifyDetails = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHistory(reqId) {
      this.loader = true;
      const payload = {
        event_id: 32,
        record_id: reqId
      };
      this.$store
        .dispatch('intWorkflow/processWorkflow', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const historyList = response.data.data;
            if (historyList.length) {
              this.sendEmailVendor(reqId);
            }
          } else {
            alert(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    sendEmailVendor(reqId) {
      const payload = {
        party_req_id: reqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/sendEmailVendor', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    upperCaseFunction(panNumber) {
      this.panNumber = panNumber.toUpperCase();
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.Select_Employee) {
        this.internalContactName = item.value_code;
        this.internalContactEmail = item.official_email_id;
        this.internalContactNumber = item.mobile.slice(2);
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_TEMPLATE_TYPE
      ) {
        this.templateType.text = item.value_meaning;
        this.templateType.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetName) {
      if (vsetName === this.internalContactName) {
        this.internalContactName = null;
        this.internalContactEmail = null;
        this.internalContactNumber = null;
      } else if (vsetName === this.templateType.value) {
        this.templateType.text = null;
        this.templateType.value = null;
      }
    },
    initiateBackgroundVerification() {
      this.showBackgroundVerificationModal = true;
    },
    backgroundVerificationModal(flag) {
      this.showBackgroundVerificationModal = flag;
    },
    getBackgroundVerificationData() {
      const payload = {
        _page: 0,
        _limit: 100,
        party_req_id: this.partyRequestId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getBackgroundVerificationData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    saveBackgroundVerificationData() {
      const payload = {
        bgvc_hdr_id: 0,
        name: this.name,
        email: this.email,
        mobile_no: this.contactNumber,
        pan_no: this.panNumber,
        party_req_id: this.partyRequestId,
        status: 'DRAFT',
        bgvc_dtls: [
          {
            bgvc_dtl_id: 0,
            address_type: 'REG',
            country: this.registeredAddress.country,
            state: this.registeredAddress.state,
            city: this.registeredAddress.city,
            pincode: this.registeredAddress.pin,
            address1: this.registeredAddress.add1,
            address2: this.registeredAddress.add2,
            nature_of_business: null
          },
          {
            bgvc_dtl_id: 0,
            address_type: 'SITE',
            country: this.siteAddress.country,
            state: this.siteAddress.state,
            city: this.siteAddress.city,
            pincode: this.siteAddress.pin,
            address1: this.siteAddress.add1,
            address2: this.siteAddress.add2,
            nature_of_business: this.natureOfBusiness
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/postBackgroundVerificationData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
